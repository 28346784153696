import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FAQContainer, FAQs } from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Flight Schools &amp; Instructors - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Get in touch with one of our professional and experienced flight instructors and tandem pilots. Find your local instructor today."
      />
    </Helmet>

    <HomeHeader slant={false}>
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Flight Schools &amp; Instructors</h2>
        <Text size="large">Learn to fly or find a tandem.</Text>
      </div>
    </HomeHeader>

    <FAQContainer style={{ marginTop: -65 }}>
      <FAQs>
        <h2 style={{ marginBottom: 20 }}>Learning &amp; Tandem Experiences</h2>
        <p style={{ fontSize: '1.3rem', marginBottom: 80 }}>
          <i>
            Want to learn, or looking for a tandem? Check out some of
            Queensland's best schools and instructors below. Begin the journey
            of learning to fly, or book in for a tandem experience today!
          </i>
        </p>

        <h4 style={{ color: '#c12422' }}>
          Rainbow Beach - CFI Jean-Luc Lejaille
        </h4>
        <p>
          "I reckon that no matter what the vocation or sport, you can't beat
          knowledge, skill and experience. Jean Luc at Rainbow Paragliding has
          these attributes in spades. Jean Luc is one of the pioneers of
          paragliding in Australia and he is also an expert instructor. I found
          his high regard for safety, comforting and his deep knowledge of
          weather conditions, reassuring. Paragliding can be a dangerous sport
          but Jean Luc is good at teaching people how to know their limits and
          to practice skills that take much of the danger away. What's more he's
          a great bloke and a lot of fun to spend time with. I would recommend
          Jean Luc to anyone who wants to enjoy the thrills of paragliding in
          the safest possible manner - he knows his stuff!" - David Tollner
        </p>
        <a href="http://www.paraglidingrainbow.com/" target="_blank">
          http://www.paraglidingrainbow.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Sunshine Coast Hang Gliding & Weight Shift Microlights - CFI David
          Cookman
        </h4>
        <p>
          "I had the pleasure of flying with David (Senior Safety Officer,
          Tandem Hang Gliding Pilot and Microlight Pilot) in his microlight
          around the shore lines of Noosa. I would have to say that it has been
          one of the major highlights of my life. The view was spectacular, the
          freedom you feel when you're up in the air is mind blowing. David is
          very experienced, funny and friendly, I felt absolutely safe and
          comfortable with him. A must for everyone to experience. Loved it."
        </p>
        <a href="https://www.localsearch.com.au/profile/david-cookman-sunshine-coast-hang-gliding/cooroibah-qld/BR5N">
          https://www.localsearch.com.au/profile/david-cookman-sunshine-coast-hang-gliding/cooroibah-qld/BR5N
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          SEQ Paragliding - CFI Shane Gingell{' '}
        </h4>
        <p>
          Shane still takes a hands on approach and you will see him onsite
          personally taking the time for one on one time with every student.
          Passionate about the sport of paragliding Shane as held numerous
          executive positions on the local flying clubs over his long flying
          career. He has been passionately involved in pilot development and
          site development for nearly 2 decades now. This gives Shane intimate
          knowledge of the local flying sites and local weather conditions
          needed for flying. Shane finds it very gratifying teaching his passion
          of paragliding to student pilots and passing on his many years of
          experience.
        </p>
        <a href="http://www.seqparagliding.com/" target="_blank">
          http://www.seqparagliding.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Lisa Bradley
        </h4>
        <p>
          Lisa and her team recognize and appreciate that every student has a
          different background, wants and needs. Training is only progressed at
          an individual’s pace, in incremental steps so they quickly gain
          mastery and feel they’ve achieved something significant from the very
          early stages of their course.
        </p>
        <a href="http://www.ozparagliding.com/" target="_blank">
          http://www.ozparagliding.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Philip Hystek
        </h4>
        <p>
          Our "Chief Flying Instructor" is Phil Hystek. Phil is one of the most
          experienced and knowledgable instructors in Australia with more than
          27 years of "free flight" instruction to his credit. Phil has taught
          in places as diverse as Australia, Indonesia, India, Iceland, USA,
          Europe and New Zealand, and is ready to take you to new heights in
          your quest for soaring freedom. Phil and his team of experienced
          instructors look forward to helping you to get airborne and find "true
          flying freedom"
        </p>
        <a href="https://www.paraglidingqueensland.com/" target="_blank">
          https://www.paraglidingqueensland.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>
          Canungra/Gold Coast - CFI Jason Turner
        </h4>
        <p>
          Paratech's Chief Flight Instructor, Jason Turner, has over 29 years
          flying experience with paragliders, hang gliders, sailplanes and
          ultralight aircraft. A full time Instructor for over 15 years, Jason
          is well known for his calm, comprehensive and adaptive teaching style
          with a high regard for student safety. Jason is still a formidable and
          well respected pilot with recent podium finishes in competitions,
          several long distance record flights and has much more to offer our
          students than most entry level instructors, thanks to his extensive
          and proven flying background.
        </p>
        <a href="https://www.paratechpg.com/" target="_blank">
          https://www.paratechpg.com/
        </a>
        <p></p>
        <h4 style={{ color: '#c12422' }}>Toowoomba - CFI Matt Fox </h4>
        <p>
          Tribal Flight Paragliding is a registered HGFA flying school and
          training facility. All schools are required to conform to the HGFA
          (and CASA) minimum standards for equipment, operational systems,
          certification and ongoing support. The school is run by Matt Fox who
          is the Chief Flight Instructor (CFI) and also a World Record holder
          for Paramotoring. As part of the training and courses, Tribal Flight
          Paragliding will introduce you to other pilots and local paragliding
          clubs and groups. Your learning is ongoing and sharing your
          experiences with other pilots is key to your success.{' '}
        </p>
        <a href="http://www.tribalflight.com.au/" target="_blank">
          http://www.tribalflight.com.au/
        </a>
        <p></p>
      </FAQs>
    </FAQContainer>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Complete the enquiry form and contact us today to find out how we can
        help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query InstructorsQuery {
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wing: file(relativePath: { eq: "instructors-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
